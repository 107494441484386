.overFlowTextLine3{
    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .overFlowTextLine1{
    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

 .svg-main svg{
   height:20px !important;
   width:20px !important;
   padding: 2px;

 }

 .none{
   display: none;
 }  